<template>
    <footer>
        <FooterContent />
        <FooterCredits />
    </footer>  
</template>

<script>
import FooterContent from "./Footer/FooterContent"
import FooterCredits from "./Footer/FooterCredits"

export default {
    name: "Footer",
    components: {
        FooterContent,
        FooterCredits,
    }
}
</script>

<style lang="scss" scoped>
footer{
    background: #f2f5f9;
    hr {
        text-align: center;
        width: 80%;
        height: 1px;
        border: 0; 
        border-bottom: 1px solid #fff;
        opacity: 0.15;
    }
    padding: 4rem 0 2rem 0;
}
@media (max-width: $mobile) { 
    footer{
        padding-top: 0;
    }
}
</style>