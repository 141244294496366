<template>
    <div id="footerCredits">
        <a href="/" target="_blank">
            <img class="socialIcon special-icon" src="/images/fheo400.webp" alt="fheo">
        </a>
        <p class="copyright" v-html="copyright"></p>
        <p style="display:inline">
            {{credits}}
        </p>
        <a href="https://www.austinrobbins.com/" target="_blank">Austin Robbins Agency</a>
    </div>
</template>

<script>
export default {
    name: "FooterCredits",
    data(){
        var d = new Date();
        return{
            copyright: "Copyright &copy " + d.getFullYear() + " Coastal Luxe Living. Cal BRE# 01859461 All Rights Reserved. 11677 San Vicente Blvd #307, Los Angeles CA 90049. Information deemed reliable not guaranteed or verified.<br><br>©2024 Berkshire Hathaway HomeServices California Properties (BHHSCP) is a member of the franchise system of BHH Affiliates LLC. BHH Affiliates LLC and BHHSCP do not guarantee accuracy of all data including measurements, conditions, and features of property. Information is obtained from various sources and will not be verified by broker or MLS. Buyer is advised to independently verify the accuracy of that information.",
            credits: "Website crafted by",
        }
    },
}
</script>

<style lang="scss" scoped>
#footerCredits{
    padding: 2rem 0 2rem 0;
    text-align: center;
    p {
        font-weight: 500;
        margin: 0;
        padding: 0.25rem 0;
        font-size: 13px;
    }
    a{
        color: #000;
        font-size: 13px;
        font-weight: 500;
        text-decoration: underline;
        &:hover{
            opacity: 0.5;
        }
    }
    .copyright{
        font-size: 10px;
        opacity: 0.4;
        color: #26407c;
        max-width: 800px;
        text-align: center;
        margin: 0 auto;
    }
}


.special-icon{
    width: 24px;
}
@media (max-width: $mobile) { 
    #footerCredits{
        padding: 2rem;
    }
}
</style>