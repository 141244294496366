<template>
    <div id="footerContent">
        <div class="biographyImage">
            <img src="/images/ryan.jpg" class="person"/>
        </div>
        <div class="shortDescription">
            <h2>
                Let's Reach New<br>
                Heights Together
            </h2>
        </div>
        <div class="footer-contact">
            <a class="phone btn" :href="'tel:'+telephone">
                {{telephone}}
            </a>
            <a class="email btn" :href="'mailto:'+email">
                Email
            </a>
        </div>
        <div class="socialMedias">
            <FooterSocialMedia :blackText="true" />
        </div>
        <div class="logoSection">
            <router-link to="/" class="logoContainer container1">
                <img src="/images/logo-retina.png" class="logo"/>
            </router-link>
            <a href="https://www.bhhscalifornia.com/real-estate-agent/ryan-sokolowski/2626" target="_blank" class="logoContainer">
                <img src="/images/logo-bhhs.png" class="logo2"/>
            </a>
        </div>
    </div>
</template>

<script>
import FooterSocialMedia from './FooterSocialMedia'
export default {
    name: "FooterContent",
    components: {
        FooterSocialMedia
    },
    data(){
        return {
            email: "ryan@coastalluxeliving.com",
            telephone: "310.344.0898",
        }
    }
}
</script>

<style lang="scss" scoped>
.person{
    width: 200px;
    border-radius: 100px;
    margin: 0 auto;
    display: block;
}
.shortDescription{
    margin: 2rem auto;
    h2{
        text-transform: unset;
        text-align: center;
    }
}
.socialMedias{
    margin: 2rem auto;
}
.logoSection{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin: 2rem auto;
    .logoContainer{
        &.container1{
            border-right: 1px #000 solid;
        }
        .logo{
            display: block;
            margin: 0 auto;
            width: 200px;
            padding-right: 2rem;
        }
        .logo2{
            display: block;
            width: 200px;
            padding-left: 2rem;
        }
    }
}
.footerContent{
    text-align: center;
    text-transform: uppercase;
    padding-top: 2rem;
}

.footer-contact{
    text-align: center;
    text-transform: uppercase;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    a{
        min-width: 200px;
    }
}
@media (max-width: $mobile) {
    .biographyImage{
        padding-top: 3rem;
    }
    .logoSection{
        .logoContainer{
            width: 100%;
            padding: 1.5rem 1rem;
            text-align: center;
            &.container1{
                border-right: unset;
            }
            &.leftLogo{
                border-right: none;
                text-align: center;
                .logo{
                    height: 40px;
                    width: auto;
                }
            }
            .logo{
                height: auto;
                width: 100%;
                max-width: 200px;
                padding: 0;
            }
            .logo2{
                height: auto;
                width: 100%;
                max-width: 200px;
                margin: 0 auto;
                padding: 0;
            }
        }
    }
    .footerContent{
        padding-top: 1rem;
    }
    .footer-contact{
        display: flex;
        flex-direction: column;
        width: 80%;
    }
}
</style>
